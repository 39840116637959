<template>
 <CContainer fluid class="vh-100 d-flex  align-items-center justify-content-center all-container" >
    <CRow class="w-50" style="max-width: 650px;" >
      <CCol class="">
        <CCard v-show="isLogin">
          <CCardBody class="p-5" >
            <CForm class="pt-5">
              <div class="text-center py-1">
                <img src="img/OxyConnect.png" @click="testViewChange"/>
              </div>
            </CForm>
            <CForm class="p-5">
              <div v-show="testView" style="border: #59718d solid 1px">
                テスト用
<!--                <CBadge color="success" class="ml-2" v-if="formData.id != null">ID:{{formData.id}}</CBadge>-->
                <CBadge color="success" class="ml-2 loginBadge"  @click="loginShort('ADMINISTRATOR')">アドミニストレータ</Cbadge>
                <CBadge color="success" class="ml-2 loginBadge"  @click="loginShort('REGISTRATOR')">レジストレータ</Cbadge>
                <CBadge color="success" class="ml-2 loginBadge"  @click="loginShort('MAINTENANCE')">メンテナンス</Cbadge>
                <CBadge color="success" class="ml-2 loginBadge"  @click="loginShort('PROVIDER')">プロバイダ</Cbadge>
                <CBadge color="success" class="ml-2 loginBadge"  @click="loginShort('DOCTOR')">医療関係者</Cbadge>
              </div>

              <CInput autocomplete="loginId"
                      :value="loginId"
                      @input="loginId = $event"
                      placeholder="ログインID" size="lg"/>
              <CInput placeholder="パスワード"
                      type="password"
                      autocomplete="new-password"
                      :value="password"
                      @input="password = $event" size="lg"/>
              <CButton color="success" size="lg" block @click="login">ログイン</CButton>
              <div class="text-center mt-3">
                <CLink color="dark" size="lg" @click="changePage('passwordReset')">ログインにお困りの方</CLink>
              </div>
            </CForm>
          </CCardBody>
        </CCard>


        <CCard class="" v-show="isPasswordReset">
          <CCardBody class="p-5">
            <CForm class="p-5">
              <h1 class="text-center mb-2">パスワードリセット</h1>
              <div class="explan mt-3">パスワードをリセットします。下記にメールアドレスを入力し、</div>
              <div class="explan ">メール送信ボタンをクリックしてください。</div>
              <div class="explan ">届いたメールに従って、パスワードを再設定してください。</div>
              <div class="explan mb-3">送信されたメールの説明を確認してパスワード変更が可能です。</div>
              <CInput placeholder="メールアドレスを入力してください"
                      autocomplete="メールアドレスを入力してください"
                      v-model="email">
                <template #prepend-content><CIcon name="cil-envelope-closed" size="lg"/></template>
              </CInput>
              <CButton color="success" block @click="passwordResetMail" size="lg">メール送信</CButton>

              <CButton color="success" variant="outline" block @click="changePage('login')" size="lg">戻る</CButton>
            </CForm>
          </CCardBody>
        </CCard>


        <CCard class="mx-8 mb-0" v-show="isRealLogin">
          <CCardBody class="p-5">
            <CForm class="p-5">
            <div class="text-center py-1 mb-3">
              <img src="img/OxyConnect.png"/>
            </div>
              <CInputFile
                  ref="file"
                  custom
                  @change="fileChange"
                  :placeholder="file == null  ? 'ログインキーファイルを選択してください' : file.name"
                  size="md">
              </CInputFile>

              <CButton color="success"  class="mt-3" block @click="realLogin" size="lg">ログイン</CButton>

              <CButton block color="success" class="mt-3" @click="isLoginKeyFileDownload = true" size="lg">ログインキーファイル取得</CButton>
              <CButton block class="mt-5" variant="outline" color="success" @click="changePage('login')" size="lg">戻る</CButton>
            </CForm>
          </CCardBody>
        </CCard>




        <CCard class="mx-8 mb-0" v-show="isFirstLogin">
          <CCardBody class="p-5">
            <CForm class="p-5">
            <div class="text-center py-1">
              <img src="img/OxyConnect.png"/>
            </div>
            <div class="text-center mt-2 mb-3 explan">パスワード変更する必要があります。</div>


              <CInput placeholder="新しいパスワードを入力してください。"
                      type="password"
                      autocomplete="firstPassword"
                      v-model="firstPassword" size="lg"/>

                <CInput placeholder="確認のため、もう一度新しいパスワードを入力してください。"
                        type="password"
                        autocomplete="firstPasswordConfirm"
                        v-model="firstPasswordConfirm" size="lg"/>

              <CButton color="success"  class="mt-2" block @click="passwordChange" size="lg">パスワード変更</CButton>

              <CButton block class="mt-3" variant="outline" color="success" @click="changePage('login')" size="lg">戻る</CButton>
            </CForm>
          </CCardBody>
        </CCard>

        <Copyright></Copyright>

        <CModal :show.sync="isLoginKeyFileDownload"
                size="md"
                :centered="true"
                title="ログインキーファイル取得">

               <CForm class="mx-3 mt-3">
                 <CInput placeholder="メールアドレスの始まりの4文字を入力してください。"
                         v-model="email4" size="lg" class="mb-1"/>
                 <div class="mt-0 pt-0" style="font-size: 1rem">
                   例 example@gmail.comならexam
                 </div>
                <CButton color="success"  class="mt-3" block @click="downloadLoginKeyFile" size="lg">ログインキーファイル取得</CButton>
               </CForm>
          <template #footer-wrapper><span></span></template>
        </CModal>
      </CCol>
    </CRow>

  </CContainer>
</template>

<script>
import axios from "axios";
import Copyright from '@/component/parts/Copyright'

export default {
  name: "Login",
  components:{
    Copyright
  },
  data() {
    return {
      testView:false,
      isLogin: true,
      isPasswordReset:false,
      isRealLogin:false,
      isLoginKeyFileDownload:false,
      isFirstLogin:false,
      loginId: null,
      password: null,
      email:null,
      file:null,
      tel:null,
      email4:null,
      firstPassword:null,
      firstPasswordConfirm:null,
      loginFormData:{
        loginId:null,
        password:null
      },
      loginUserInfo:{}
    };
  },
  computed: {
    count() {
      return this.$store.state.count;
    },
    token() {
      return this.$store.state.token;
    },mode(){

      return process.env.VUE_APP_MODE;
    }
  },
  mounted() {
    this.$store.commit("setUser",{});
    //サイドバーを記憶しない
    this.$store.state.sidebarShow = true;
    this.$store.state.sidebarMinimize = false;
    this.testView = this.mode != 'prod';

  },
  methods: {
    fileChange:function(files,event){
      this.file = files[0];
    },
    changePage(pageType){
      this.isLogin = false;
      this.isPasswordReset = false;
      this.isRealLogin = false;
      this.isFirstLogin = false;
    //  this.isLoginKeyFileDownload = false;
      if(pageType == 'login' ){
        this.isLogin = true;
      }else if(pageType =='passwordReset'){
        this.isPasswordReset = true;
      }else if(pageType =='realLogin'){
        this.isRealLogin=true;
      }else if(pageType == 'firstLogin'){
        this.isFirstLogin = true;
      }
      // else if(pageType == 'loginKeyFileDownload'){
      //   this.isLoginKeyFileDownload = true;
      // }
    },
    clear:function(){
      this.file = null;
    },
    downloadLoginKeyFile(){
      this.axios.post('/api/app/loginKeyDownload',
          Object.assign({},{email4:this.email4},this.loginFormData)
          , {headers: {'Content-Type': 'application/json'}
          ,responseType: 'blob'}).then(
          response => {
            console.log("/api/app/loginKeyDownload",response);
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', decodeURI(response.headers.filename));
            document.body.appendChild(link);
            link.click();
          }).catch(error => this.$swal("入力情報が不正です。")

      ).finally(() => this.isLoading = false);

    },
    loginTest() {
      this.axios.post("/api/login/test", {loginId:this.loginId,password: this.password},{headers: { "Content-Type": "application/json" }})
          .then(response => {
            this.$store.commit("setUser", response.data.result);
            this.$router.replace({ path: "/pages/notice/list" });
          })
          .catch(error => {
            if (!error.response) {
              console.log("Error: Network Error");
            } else {
              console.log("error-", error.response.data);
            }
          })
          .finally(ee => {
            console.log("finally");
            console.log("finally", ee);
          });
    },
    login() {
      var formData = new FormData();
      formData.append("loginId", this.loginId);
      formData.append("password", this.password);
      //this.axios.post("/api/login", {loginId: this.loginId,password: this.password},{headers: { "Content-Type": "application/json" }})
      this.axios.post("/api/login", formData,{headers: {"Content-Type": "multipart/form-data"}})
        .then(response => {
          console.log("response------------------------", response.data);
          this.loginFormData.loginId = this.loginId;
          this.loginFormData.password = this.password;
          this.changePage('realLogin');
        })
        .catch(error => {
          if (!error.response) {
            // network error
            console.log("Error: Network Error");
          } else {
            console.log("error-", error.response.data);
          }
        })
        .finally(ee => {
          console.log("finally");
          console.log("finally", ee);
        });
    },
    realLogin(){
      var formData = new FormData();
      formData.append("loginId", this.loginFormData.loginId);
      formData.append("password", this.loginFormData.password);
      if(this.file == null){
        this.$swal("ファイルを指定してください。")
        return;
      }
      formData.append("file",  this.file);

      this.axios.post("/api/login", formData,{headers: {"Content-Type": "multipart/form-data"}})
          .then(response => {
            // 初回ログインの場合
            if(response.data.result.firstLoginStatus == 'BEFORE_PASSWORD_CHANGE' || response.data.result.isPasswordChange){
              this.isFirstLogin = true;
              this.loginUserInfo = response.data.result;
              this.changePage('firstLogin');
            }else{
              this.$store.commit("setUser", response.data.result);
              this.$router.replace({ path: "/pages/notice/list" });
            }
            //  location.href="/#/pages/notice/list";
          })
          .catch(error => {
            if (!error.response) {
              // network error
              console.log("Error: Network Error");
            } else {
              console.log("error-", error.response.data);
            }
          })
          .finally(ee => {
            console.log("finally");
            console.log("finally", ee);
          });
    },
    passwordResetMail() {
      axios
        .post("/api/user/passwordResetMail",{email: this.email},{headers: { "Content-Type": "application/json" }})
        .then(response => {
          console.log("response", response);
        })
        .catch(error => {
          console.log("error", error);
          console.log("error", error.response.data);
        })
        .finally(e => {
          console.log("finally");
          console.log("finally", e);
        });
    },loginShort:function (data) {
        this.loginId = data;
        this.password = "password";
        this.loginTest();
      },passwordChange(){
      if(this.firstPassword != this.firstPasswordConfirm){
        this.$swal("パスワードが一致しません。")
        return;
      }

      if (this.firstPassword == "" || this.firstPassword.length < 8) {
        this.$swal("パスワードは8桁以上32桁以下の半角英数字・記号を入力してください。");
        return;
      }

        this.axios.post('/api/user/password/change',
            {newPassword:this.firstPassword,currPassword:this.loginFormData.password} ,
            {headers: {'Content-Type': 'application/json','token':this.loginUserInfo.token}}).then(response => {
          this.$store.commit("setUser", this.loginUserInfo);
          this.$router.replace({ path: "/pages/notice/list" });
        }).catch(error => {
          console.log('error', error.response.data);
        }).finally(() => {
          console.log('finally');
        });
    },testViewChange(){
        this.testView = this.mode != 'prod';
        //process.env.VUE_APP_CLIENT_URL
    }
  }
};
</script>

<style type="text/css" scoped>
  .loginBadge{
    cursor:pointer;
  }
  .all-container{
    background-color: #F0F0F0;
  }
  .card,.card-body{
    background-color: #FFFFFF;
  }
  .card-body {
    border: solid 1px #969191;
  }
  a {
    color:black;
    font-size: 1rem;
  }

  .explan{
    color:black;
    font-size: 1rem;
  }
  .custom-file-label::after{
     content: "ファイルを選択";
  }
  /*[src$=png]{*/
  /*  margin-top: 30px;*/
  /*}*/
</style>